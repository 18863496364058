import { Routes, Route } from "react-router-dom";
import styles from "./App.module.scss";
import Header from "./Components/Header";
import Workers from "./Components/Workers";
import Locations from "./Components/Locations";
import WorkUnits from "./Components/WorkUnits";
import Create from "./Components/Create";

import { FC } from "react";
import {
  fetchAddons,
  fetchLocations,
  fetchRoles,
  fetchWorkUnits,
  fetchWorkers,
} from "./utils/firebase";
import { WorkerType } from "./models/Worker";
import logo from "./assets/greif.png";

import { useEffect, useState } from "react";
import { LocationType } from "./models/Location";
import { WorkUnitType } from "./models/WorkUnit";
import { RoleType } from "./models/Roles";
import { addCostToLocations, addCostToWorkers } from "./utils/cost";
import { AddonType } from "./models/Addon";
import Loading from "./Components/Loading";
import EditAddons from "./Components/forms/EditAddons";
import EditRoles from "./Components/forms/EditRoles";
import HotelCreateUnit from "./Components/HotelCreateUnit";
import WorkerApp from "./Components/WorkerApp";

type Props = {
  user: any;
  password: string
};

const App: FC<Props> = ({ user, password }) => {
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [workers, setWorkers] = useState<WorkerType[]>([]);
  const [workUnits, setWorkUnits] = useState<WorkUnitType[]>([]);
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [addons, setAddons] = useState<AddonType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [refresh, setRefresh] = useState(0);

  const doRefresh = () => {
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    const fetchLocationsData = async () => {
      try {
        const loc = await fetchLocations();
        setLocations(loc);
        return loc;
      } catch (error) {
        console.error("Error fetching locations:", error); // Handle errors
      }
    };

    const fetchWorkersData = async (roles: RoleType[]) => {
      try {
        const wor = await fetchWorkers(roles);
        setWorkers(wor);
        return wor;
      } catch (error) {
        console.error("Error fetching workers:", error); // Handle errors
      }
    };

    const fetchRoleData = async () => {
      try {
        const ro = await fetchRoles();
        setRoles(ro);
        return ro;
      } catch (error) {
        console.error("Error fetching roles:", error); // Handle errors
      }
    };

    const fetchWorkUnitsData = async (
      workers: WorkerType[],
      locations: LocationType[],
      roles: RoleType[]
    ) => {
      try {
        const wu = await fetchWorkUnits(workers, locations, roles);

        setWorkUnits(wu);
        return wu;
      } catch (error) {
        console.error("Error fetching workunits:", error); // Handle errors
      }
    };

    const fetchAddonsData = async () => {
      try {
        const a = await fetchAddons();

        setAddons(a);
        return a;
      } catch (error) {
        console.error("Error fetching workunits:", error); // Handle errors
      }
    };

    const fetch = async () => {
      setIsLoading(true);
      const l = await fetchLocationsData();
      const r = await fetchRoleData();

      const w = await fetchWorkersData(r ? r : []);
      const a = await fetchAddonsData();
      console.log(l);
      console.log(w);
      console.log(r);
      console.log(a);
      const wu = await fetchWorkUnitsData(w ? w : [], l ? l : [], r ? r : []);
      console.log(wu);
      addCostToLocations(l ? l : [], wu ? wu : []);
      addCostToWorkers(w ? w : [], wu ? wu : []);
      setLocations(l ? l : []);
      setWorkers(w ? w : []);
      setIsLoading(false);
    };
    fetch();
  }, [refresh]);




  if(isLoading) {
    return <Loading />
  }

  if (user.email === "hotel@hawaii.com") {
    return (
      <HotelCreateUnit
        addons={addons}
        locations={locations}
        roles={roles}
        workers={workers}
      />
    );
  }

  if (user.email !== "manager@hawaii.com") {
    const loggedUser = workers.find(w => w.uid === user.uid);
    const workerWorkUnits = workUnits.filter(wu => wu.worker.id === loggedUser!.id)
    return (
      < WorkerApp addons={addons} locations={locations} roles={roles} workers={workers} loggedUser={loggedUser!} workUnits={workerWorkUnits} setWorkUnits={setWorkUnits} />
    )
  }

  return (
    <body className={styles.app}>
      <Header refresh={doRefresh} />
      <div className={styles.body}>
        <Routes>
          <Route
            path="/"
            element={
              isLoading ? (
                <Loading />
              ) : (
                <div className={styles.wellcome}>
                  <h1 className={styles.title}>Welcome!</h1>
                  <img src={logo} alt="logo" className={styles.logo}></img>
                </div>
              )
            }
          />
          <Route
            path="/workers"
            element={
              isLoading ? (
                <Loading />
              ) : (
                <Workers
                locations={locations}
                  addons={addons}
                  setWorkers={setWorkers}
                  workers={workers}
                  workUnits={workUnits}
                  roles={roles}
                />
              )
            }
          />

          <Route
            path="/locations"
            element={
              isLoading ? (
                <Loading />
              ) : (
                <Locations
                roles={roles}
                  setLocations={setLocations}
                  workUnits={workUnits}
                  locations={locations}
                  workers={workers}
                />
              )
            }
          />
          <Route
            path="/workunits"
            element={
              isLoading ? (
                <Loading />
              ) : (
                <WorkUnits
                  addons={addons}
                  locations={locations}
                  roles={roles}
                  setWorkUnits={setWorkUnits}
                  workers={workers}
                  workUnits={workUnits}
                />
              )
            }
          />

      
          <Route
            path="/create"
            element={
              isLoading ? (
                <Loading />
              ) : (
                <Create
                user={{password: password, email: user.email}}
                  workers={workers}
                  locations={locations}
                  addons={addons}
                  roles={roles}
                  setLocations={setLocations}
                  setWorkUnits={setWorkUnits}
                  setWorkers={setWorkers}
                  setAddons={setAddons}
                  setRoles={setRoles}
                />
              )
            }
          />

          <Route
            path="/addons"
            element={
              isLoading ? (
                <Loading />
              ) : (
                <EditAddons addons={addons} setAddons={setAddons} />
              )
            }
          />

          <Route
            path="/roles"
            element={
              isLoading ? (
                <Loading />
              ) : (
                <EditRoles roles={roles} setRoles={setRoles} />
              )
            }
          />
        </Routes>
      </div>
    </body>
  );
};

export default App;
