import { Dispatch, FC, SetStateAction, useRef, useState } from "react";

import { Button, Input, Popconfirm, Space, Table, Tag } from "antd";
import type { GetRef, TableColumnType, TableProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import styles from "./Workers.module.scss";
import { FilterDropdownProps } from "antd/es/table/interface";
import { Link } from "react-router-dom";
import { WorkerType } from "../models/Worker";
import { WorkUnitType, WorkUnitUtils } from "../models/WorkUnit";
import EditWorker from "./forms/EditWorker";
import { RoleType } from "../models/Roles";
import { deleteWorker } from "../utils/firebase";
import Loading from "./Loading";
import { AddonType } from "../models/Addon";
import { LocationType } from "../models/Location";
import { strignsToColors } from "../utils/colors";

interface DataType {
  index: number;
  key: string;
  name: string;
  location: string;
  costLastWeek: number;
  costLastMonth: number;
  costCurrentWeek: number;
  costCurrentMonth: number;
  costCurrentDay: number;
  costYesterday: number;
  costTotal: number;
  worker: WorkerType;
}

type DataIndex = keyof DataType;
type InputRef = GetRef<typeof Input>;

type Props = {
  addons: AddonType[];
  locations: LocationType[]
  workers: WorkerType[];
  workUnits: WorkUnitType[];
  roles: RoleType[];
  setWorkers: Dispatch<SetStateAction<WorkerType[]>>;
};
const Workers: FC<Props> = ({
  workers,
  locations,
  workUnits,
  roles,
  setWorkers,
  addons,
}) => {
  const [editWorker, setEditWorker] = useState<WorkerType>();

  const colorMapLocations = strignsToColors(locations.map(l => l.name))

  function finishEdit() {
    setEditWorker(undefined);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const unitUtils = new WorkUnitUtils();

  if (editWorker) {
    return (
      <EditWorker
        addons={addons}
        worker={editWorker}
        finishEdit={finishEdit}
        roles={roles}
        setWorkers={setWorkers}
      />
    );
  }

  const aktivUnits = workUnits.filter((unit) => unitUtils.isCurrent(unit));

  const updatedData: DataType[] = workers.map((worker, index) => {
    const loc = aktivUnits.find((unit) => unit.worker.id === worker.id)
      ?.location.name;
    return {
      index: index,
      key: worker.id,
      name: worker.name + " | " + worker.nick,
      location: loc ? loc : "",
      costLastWeek: worker.costLastWeek!,
      costLastMonth: worker.costLastMonth!,
      costCurrentWeek: worker.costCurrentWeek!,
      costCurrentMonth: worker.costCurrentMonth!,
      costCurrentDay: worker.costCurrentDay!,
      costYesterday: worker.costYesterday!,
      costTotal: worker.costTotal!,
      worker: worker,
    };
  });

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
      ...getColumnSearchProps("name"),
      sorter: (a, b) => b.name.localeCompare(a.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Currently at",
      dataIndex: "location",
      key: "location",
      render: (text) => <p><span style={{display: 'inline-flex', 
      alignItems: 'center', 
      marginRight: '5px',
      width: '12px',
      height: '12px',
      backgroundColor: colorMapLocations[text],
      borderRadius: '50%', 
    
    }} ></span>{text}</p>,
      // ...getColumnSearchProps("name"),
    },

    {
      title: "Cost in $",
      children: [
        {
          title: "Today",
          dataIndex: "costCurrentDay",
          key: "costCurrentDay",
          render: (val) => (val === 0 || val === undefined ? "" : "$" + val),
        },
        {
          title: "Yesterday",
          dataIndex: "costYesterday",
          key: "costYesterday",
          render: (val) => (val === 0 || val === undefined ? "" : "$" + val),
        },
        {
          title: "This Week",
          dataIndex: "costCurrentWeek",
          key: "costCurrentWeek",
          render: (val) => (val === 0 || val === undefined ? "" : "$" + val),
        },
        {
          title: "Last Week",
          dataIndex: "costLastWeek",
          key: "costLastWeek",
          render: (val) => (val === 0 || val === undefined ? "" : "$" + val),
        },
        {
          title: "This Month",
          dataIndex: "costCurrentMonth",
          key: "costCurrentMonth",
          render: (val) => (val === 0 || val === undefined ? "" : "$" + val),
        },

        {
          title: "Last Month",
          dataIndex: "costLastMonth",
          key: "costLastMonth",
          render: (val) => (val === 0 || val === undefined ? "" : "$" + val),
        },
        {
          title: "Total",
          dataIndex: "costTotal",
          key: "costTotal",
          render: (val) => {
            return val === 0 || val === undefined ? "" : "$" + val;
          },
        },
      ],
    },

    {
      title: "Action",
      key: "action",
      render: (val, record) => {
        return (
          <Space size="middle">
            <a onClick={() => setEditWorker(val.worker)}>Edit</a>

            <Popconfirm
              placement="topRight"
              title={"Delete Location"}
              description={"Are you sure you want to delete " + val.worker.name}
              okText="Yes"
              cancelText="Cancel"
              onConfirm={async () => {
                setIsLoading(true);
                await deleteWorker(val.worker);
                setWorkers((locs: WorkerType[]) => {
                  return [
                    ...locs.filter((l: WorkerType) => l.id !== val.worker.id),
                  ];
                });
                setIsLoading(false);
              }}
            >
              <a className={styles.delete}>Delete</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {isLoading && <Loading />}
      <Table
        pagination={{ hideOnSinglePage: true }}
        columns={columns}
        dataSource={updatedData}
        rowClassName={({ index }) => (index % 2 === 1 ? "even-row" : "")}
        className={styles.table}
      />
    </>
  );
};

export default Workers;
