import { Dispatch, FC, SetStateAction, useRef, useState } from "react";

import {
  Badge,
  Button,
  Dropdown,
  Input,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import type {
  GetRef,
  TableColumnType,
  TableColumnsType,
  TableProps,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import styles from "./Locations.module.scss";
import { FilterDropdownProps } from "antd/es/table/interface";


import { LocationType } from "../models/Location";
import { WorkerType } from "../models/Worker";
import { WorkUnitType, WorkUnitUtils } from "../models/WorkUnit";
import { formatDateToUS, getTimeInAMPM } from "../utils/time";
import { AddonType } from "../models/Addon";
import { deleteLocation } from "../utils/firebase";
import EditLocation from "./forms/EditLocation";
import Loading from "./Loading";
import { RoleType } from "../models/Roles";
import { strignsToColors } from "../utils/colors";

interface DataType {
  index: number;
  key: string;
  name: string;
  costLastWeek: number;
  costLastMonth: number;
  costCurrentWeek: number;
  costCurrentMonth: number;
  costCurrentDay: number;
  costYesterday: number;
  costTotal: number;
  workUnits: WorkUnitType[];
  expandable: boolean;
  location: LocationType;
}

interface ExpandedDataType {
  key: string;
  startTime: string;
  worker: string;
  addons: string[];
  workUnit: WorkUnitType;
}

type DataIndex = keyof DataType;
type InputRef = GetRef<typeof Input>;

type Props = {
  setLocations: Dispatch<SetStateAction<LocationType[]>>;
  locations: LocationType[];
  workers: WorkerType[];
  workUnits: WorkUnitType[];
  roles: RoleType[];
};
const Locations: FC<Props> = ({
  roles,
  locations,
  workers,
  workUnits,
  setLocations,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [editLocaiton, setEditLocation] = useState<LocationType>();

  const workUnitUtils = new WorkUnitUtils();

  const colorMap = strignsToColors(locations.map(l => l.name))
  const colorMapRoles = strignsToColors(roles.map(l => l.name))

  function finishEdit() {
    setEditLocation(undefined);
  }

  if (editLocaiton) {
    return (
      <EditLocation
        location={editLocaiton}
        finishEdit={finishEdit}
        setLocations={setLocations}
      />
    );
  }

  const updatedData: DataType[] = locations.map((l, index) => {
    return {
      index: index,
      key: l.id,
      name: l.name,
      workUnits: [],
      expandable: false,
      location: l,
      costLastWeek: l.costLastWeek!,
      costLastMonth: l.costLastMonth!,
      costCurrentWeek: l.costCurrentWeek!,
      costCurrentMonth: l.costCurrentMonth!,
      costCurrentDay: l.costCurrentDay!,
      costYesterday: l.costYesterday!,
      costTotal: l.costTotal!,
    };
  });

  const updatedWorkUnits = workUnits.filter(
    (unit) => !workUnitUtils.hasFinished(unit)
  );

  updatedData.forEach((data) => {
    updatedWorkUnits.forEach((unit) => {
      if (unit.location.id === data.location.id) {
        data.workUnits.push(unit);
        data.expandable = true;
      }
    });
  });

  /*   const expandedRowRender = (record: any) => {
    if (!record.expandable) return null; */

  const createExpandWorkUnits = (record: any) => {
    const workUnits = record.workUnits;
    const columns = [
      { title: "Currently Working", dataIndex: "worker", key: "worker" },
      { title: "Start", dataIndex: "startTime", key: "startTime" },
      {
        title: "Roles",
        dataIndex: "roles",
        key: "roles",
      /*   render: (x: any, val: any) => {
          return val.workUnit.roles
            .map((r: RoleType) => r.name + ": $" + r.basicPay)
            .join(", ");
        }, */
        render: (x: any, val: any) => {
          if (val.workUnit.roles.length === 0 ) return ""
          const spans = val.workUnit.roles.map((a: any, index: any) => <span
      
          
          ><span     style={{display: 'inline-flex', 
          alignItems: 'center', 
          marginRight: '5px',
          width: '12px',
          height: '12px',
          backgroundColor: colorMapRoles[a.name],
          borderRadius: '50%', 
        
        }}></span>{a.name + ": $" + a.basicPay }{index < val.workUnit.roles.length -1 ? ", " : ""}</span> )
         return <p>{spans}</p>
        },
      },
      { title: "Addons", dataIndex: "addons", key: "addons" },
      { title: "Total", dataIndex: "total", key: "total" },
    ];
    const data: ExpandedDataType[] = workUnits.map((unit: any) => {
      return {
        workUnit: unit,
        key: unit.id,
        worker: unit.worker.name,
        addons: unit.addons
          .map((a: AddonType) => a.name + ": $" + a.basicPay)
          .join(", "),
        startTime:
          formatDateToUS(unit.start) + " - " + getTimeInAMPM(unit.start),
        total: "$" + unit.total,
      };
    });

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      render: (text) => <p><span style={{display: 'inline-flex', 
      alignItems: 'center', 
      marginRight: '5px',
      width: '12px',
      height: '12px',
      backgroundColor: colorMap[text],
      borderRadius: '50%', 
    
    }} ></span>{text}</p>,
      sorter: (a, b) => b.name.localeCompare(a.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Active Workers",
      dataIndex: "workUnits",
      key: "workUnits",
      render: (val: WorkUnitType[]) =>
        val.length === 0 ? "" : val.length.toString(),
    },

    {
      title: "Cost in $",
      children: [
        {
          title: "Today",
          dataIndex: "costCurrentDay",
          key: "costCurrentDay",
          render: (val) => val === undefined ? "" : (val === 0 ? "" : "$" + val),
        },
        {
          title: "Yesterday",
          dataIndex: "costYesterday",
          key: "costYesterday",
          render: (val) =>   val === undefined ? "" : (val === 0 ? "" : "$" + val),
        },
        {
          title: "This Week",
          dataIndex: "costCurrentWeek",
          key: "costCurrentWeek",
          render: (val) =>  val === undefined ? "" : (val === 0 ? "" : "$" + val),
        },
        {
          title: "Last Week",
          dataIndex: "costLastWeek",
          key: "costLastWeek",
          render: (val) =>  val === undefined ? "" : (val === 0 ? "" : "$" + val),
        },
        {
          title: "This Month",
          dataIndex: "costCurrentMonth",
          key: "costCurrentMonth",
          render: (val) =>  val === undefined ? "" : (val === 0 ? "" : "$" + val),
        },

        {
          title: "Last Month",
          dataIndex: "costLastMonth",
          key: "costLastMonth",
          render: (val) =>  val === undefined ? "" : (val === 0 ? "" : "$" + val),
        },
        {
          title: "Total",
          dataIndex: "costTotal",
          key: "costTotal",
          render: (val) => {
            return  val === undefined ? "" : (val === 0 ? "" : "$" + val)
          },
        },
      ],
    },

    {
      title: "Action",
      key: "action",
      render: (val, record) => (
        <Space size="middle">
          <a onClick={() => setEditLocation(val.location)}>Edit</a>

          <Popconfirm
            placement="topRight"
            title={"Delete Location"}
            description={"Are you sure you want to delete " + val.location.name}
            okText="Yes"
            cancelText="Cancel"
            onConfirm={async () => {
              setIsLoading(true);
              await deleteLocation(val.location);
              setLocations((locs: LocationType[]) => {
                return [
                  ...locs.filter((l: LocationType) => l.id !== val.location.id),
                ];
              });
              setIsLoading(false);
            }}
          >
            <a className={styles.delete}>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const isRowExpandable = (record: any) => {
    return record.expandable === true;
  };

  return (
    <div>
      {isLoading && <Loading />}
      <Table
        pagination={{ hideOnSinglePage: true }}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: createExpandWorkUnits,
          // defaultExpandedRowKeys: defaultExpandedRowKeys,
          rowExpandable: isRowExpandable,
          defaultExpandAllRows: false,
        }}
        columns={columns}
        dataSource={updatedData}
        rowClassName={({ index }) => (index % 2 === 1 ? "even-row" : "")}
        className={styles.table}

      />
    </div>
  );
};

export default Locations;
