const d3 = require('d3');

type colorMap = {[label: string]: string}

export const strignsToColors = (strings: string[]) => {

    const colorScale = d3.scaleOrdinal()
    .domain(strings)
    .range(['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075'])

    const colors: colorMap = {}
     strings.forEach(s =>  {colors[s] = colorScale(s)})

    return colors

}